<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Header :reloadBalance="reloadBalance"></Header>

        <PayInvoice
          @sucessPayment="reloadAll"
          ref="payInvoice"
          :cards="cards"
          :invoices="selectedInvoices"
          :invoice="selectedInvoice"
        />
        <v-card
          v-if="loading"
          tile
          class="row"
          style="width: 100%; height: 200px"
        >
          <loading-circle v-if="loading" />
        </v-card>

        <div v-else>
          <NoCard
            :showAddCard="showAddCard"
            @sucessAddCard="showAddCard = !showAddCard"
          />
          <v-card v-if="!showAddCard" tile class="row" style="width: 100%">
            <v-tabs v-model="tab">
              <v-tab key="invoices">Invoices</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="width: 100%">
              <v-tab-item key="invoices">
                <v-card-text>
                  <v-data-table
                    class="table-freezes"
                    :headers="invoicesHeaders"
                    :items="invoicesItems"
                    :loading="invoicesLoading"
                    :items-per-page="10"
                  >
                    <template v-slot:[`item.method`]="{ item }">
                      {{ item.duedate }}
                    </template>

                    <template v-slot:[`item.details`]="{ item }">
                      {{ item.details }}
                    </template>
                    <template v-slot:[`item.invoicedAmount`]="{ item }">
                      {{
                        item.invoicedAmount
                          ? formatMoney(item.invoicedAmount)
                          : '$0.00'
                      }}
                    </template>
                    <template v-slot:[`item.invoice`]="{ item }">
                      {{ item.invoice }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      {{ item.status ? item.status : '' }}
                    </template>
                    <template v-slot:[`item.option`]="{ item }">
                      <v-btn
                        color="#DECA76"
                        :style="styleCustomFont"
                        light
                        @click="payInvoice(item)"
                        :loading="invoicesLoading"
                        :disabled="item.option"
                      >
                        Pay now
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingCircle from '@/components/LoadingCircle.vue';
import Header from './Header.vue';
import _ from 'lodash';
import { api, auth } from '../../sharedPlugin';
import PayInvoice from '@/components/cryo/PayInvoice.vue';
import NoCard from './NoCard.vue';
import device from '../../utils/device';
export default {
  props: ['cards'],
  components: {
    LoadingCircle,
    Header,
    PayInvoice,
    NoCard
  },
  data() {
    return {
      styleCustomFont:
        device() === 'mobile'
          ? {
              'font-size': '1.8vw',
              height: '25px'
            }
          : {
              'font-size': '0.6vw',
              height: '25px'
            },
      showAddCard: false,
      tab: 0,
      reloadBalance: false,
      invoicesLoading: false,
      invoicesItems: [],
      invoicesHeaders: [
        { text: 'Due Date', value: 'dueDate', sortable: false },
        { text: 'Details', value: 'details', sortable: false },
        { text: 'Amount', value: 'invoicedAmount', sortable: false },
        { text: 'Invoice Number', value: 'invoice', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Options', value: 'option', sortable: false, align: 'center' }
      ],
      loading: false,
      selectedInvoices: null,
      selectedInvoice: null
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    reloadAll() {
      this.getInvoices();
      this.reloadBalance = !this.reloadBalance;
    },
    formatMoney(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      if (!amount) return '$0.00';
      return formatter.format(amount);
    },
    async getInvoices() {
      this.invoicesLoading = true;
      await api.Cryo.getCryoInvoices()
        .then((res) => {
          this.invoicesItems = res.map((item) => ({
            invoice: item.id,
            details: item.description,
            invoicedAmount: item.total,
            paid: item.paid,
            balancedue: item.balancedue,
            dueDate: item.duedate,
            status: item.status.toUpperCase(),
            type: item.type,
            option:
              item.status.toUpperCase() === 'OPEN' ||
              item.status.toUpperCase() === 'PAST DUE'
                ? false
                : true
          }));
        })
        .finally(() => (this.invoicesLoading = false));
    },
    payInvoice(item) {
      this.selectedInvoices = null;
      item.amount = item.balancedue;
      this.selectedInvoice = item;

      this.$refs.payInvoice.show();
    }
  },
  created() {
    if ((this.cards && this.cards.length === 0) || this.cards === null) {
      this.showAddCard = true;
    }
    this.getInvoices();
  }
};
</script>

<style>
.table-freezes .v-data-table__wrapper table tbody tr td {
  height: 32px !important;
  font-size: 0.7rem !important;
}
.table-freezes .v-data-table__wrapper .v-data-table-header tr th {
  color: #000000 !important;
}
.v-data-table.row-height-50 td {
  height: 18px !important;
}
</style>
